import React from 'react';
import { graphql } from 'gatsby';
import TemplateDefault from './default';
import getParagraph from '../components/paragraphs/paragraph-loader';
import SEO from '../components/seo';
import OrganismIslands from '../components/organism/islands';
import GardenExplorer from '../components/organism/garden-explorer';

export default function IndexPage({ data }) {
  const paragraphs = data.nodePage.relationships.paragraphs.map(getParagraph);

  return (
    <TemplateDefault noSideNav>
      <GardenExplorer
        image={data.nodePage.explorerImage}
        text={data.nodePage.explorerText}
      />

      <main className="blueBg tw-relative tw-z-20">
        <OrganismIslands
          title={data.nodePage.title}
          description={data.nodePage.description.value}
        />

        <div>{paragraphs}</div>
      </main>
    </TemplateDefault>
  );
}

export const query = graphql`
  query ($drupalId: String!) {
    nodePage: nodeAnbgLhHomepage(drupal_id: { eq: $drupalId }) {
      title
      drupalId: drupal_id
      path {
        alias
      }
      description: field_anbg_lh_description {
        value
      }
      explorerImage: field_anbg_lh_explorer_img
      explorerText: field_anbg_lh_explorer_text
      relationships {
        paragraphs: field_anbg_lh_content {
          type: __typename
          ...ParagraphTwoThirds
        }
      }
    }
  }
`;

export function Head() {
  return <SEO />;
}

import React from 'react';
import { Link } from 'gatsby';

const park = process.env.GATSBY_PARK;

const regex = new RegExp(`^/${park}`);

export default function AtomPaLink(props) {
  let to = props.uri.replace(regex, '');

  if (to === '') {
    to = '/';
  }

  if (to.startsWith('/')) {
    return <Link to={to}>{props.text}</Link>;
  }

  return (
    <a href={to}>
      <span>{props.text}</span>
    </a>
  );
}

import React from 'react';
import ButtonsParagraph from '../parksaus-demo/buttons';
import TwoThirdsParagraph from './two-thirds';
import ResourceList from './resource-list';
import EmbedVideoParagraph from './embed-video';

const components = {
  paragraph__buttons: ButtonsParagraph,
  paragraph__anbg_lh_twothirds: TwoThirdsParagraph,
  paragraph__anbg_lh_resource_list: ResourceList,
  paragraph__embed_video: EmbedVideoParagraph,
};

const getParagraph = (node) => {
  if (components.hasOwnProperty(node.type)) {
    const ParagraphComponent = components[node.type];
    if (ParagraphComponent) {
      return <ParagraphComponent key={node.id} node={node} />;
    }
  }
  return <p key={node.type.id}>Unknown type {node.__typename}</p>;
};

export default getParagraph;

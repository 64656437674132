import React from 'react';
import { graphql } from 'gatsby';
import AtomPaLink from './pa-link';

export default function ButtonsParagraph({ node }) {
  const buttons = node.links.map((link, index) => (
    <AtomPaLink
      key={`button-${index}`}
      style={node.style}
      text={link.title}
      uri={link.uri || link.uri_alias}
    />
  ));

  const classNames = ['buttons', `t-${node.field_align || 'left'}`];

  return (
    <section className="">
      <div className="">
        <div className="">
          <div className="block">
            <p className={classNames.join(' ')}>{buttons}</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export const fragment = graphql`
  fragment ParagraphButtons on paragraph__buttons {
    id
    field_align
    style: field_style
    wrapperClasses: field_wrapper_classes
    links: field_link {
      title
      uri
      uri_alias
    }
  }
`;

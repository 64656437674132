import React, { useEffect, useMemo, useState } from 'react';
import './animations.css';
import sanitizeHtml from 'sanitize-html';
import fencePath from '../../../images/explorers/fence.svg';
import islandPath from '../../../images/explorers/island.svg';
import banksiaPath from '../../../images/explorers/banksia.svg';
import beePath from '../../../images/explorers/bee.svg';
import beeFlippedPath from '../../../images/explorers/beeflipped.svg';
import useLocalStorage from '../../../hooks/use-local-storage';

/**
 *
 * @params text - string - message said
 * @params image - string - from dropdown dictating animated SVG used.
 *      Options are: 01 (both smiling) 02 (bee smiling) 03 (banksia smiling) 04 (flipped bee smiling)
 *
 */
// unfortunately, I can't use transform to flip the image because the animation class overrides

export enum ImageEnum {
  '01' = '01',
  '02' = '02',
  '03' = '03',
  '04' = '04',
}

interface IGardenExplorer {
  image?: ImageEnum;
  text: string;
}

interface IHidden {
  visible: boolean;
  ttl: number;
}

export default function GardenExplorer(props: IGardenExplorer) {
  const [showText, setShowText] = useState(true);
  const [hidden, setHidden] = useState<IHidden | undefined>(undefined);
  const [localStorage, setLocalStorage] = useLocalStorage('isHidden', {
    visible: true,
    ttl: Date.now() + 432000000,
  });

  useEffect(() => {
    setHidden(localStorage);
  }, []);

  useEffect(() => {
    setLocalStorage(hidden);
  }, [hidden, setLocalStorage]);

  const explorerFallback = useMemo(
    () => props.image || ImageEnum['01'],
    [props.image],
  );

  const safeContent = () => {
    if (props.text) {
      return sanitizeHtml(props.text.replaceAll('|', '<br/>'), {
        allowedTags: sanitizeHtml.defaults.allowedTags.concat(['a']),
        allowedAttributes: {
          p: ['class'],
          a: ['href', 'target', 'rel', 'class'],
        },
      });
    } else {
      return 'Hey there! We are your garden explorers, and we will be your guide through this online hub.';
    }
  };

  if (!hidden) {
    return <></>;
  }

  return (
    <div
      className={
        'tw-fixed tw-bottom-20 -tw-right-10 tw-z-40 tw-h-24 tw-w-52 tw-shadow-sm tw-transition-all tw-duration-300 md:tw-right-10'
      }
      style={{
        visibility: hidden.visible ? 'visible' : 'hidden',
        opacity: hidden.visible ? '1' : '0',
      }}
    >
      <div
        className={`tw-absolute -tw-left-[115%] tw-right-[90%] -tw-top-[51%] tw-z-10 tw-rounded-lg tw-bg-anbg-cream tw-px-5 tw-py-4 tw-text-anbg-dark-green tw-drop-shadow-solid-yellow tw-transition-all ${
          showText ? 'tw-opacity-100' : 'tw-opacity-0'
        }`}
      >
        <button
          type="button"
          className="tw-text-sans tw-absolute -tw-top-2.5 tw-left-1.5 tw-text-[1.8rem] tw-font-bold tw-text-[#e66f40] tw-transition-all tw-duration-200 hover:tw-scale-[120%]"
          onClick={() => {
            setShowText(false);
          }}
        >
          <span aria-hidden={'true'}>&#x2A2F;</span>
          <span className="tw-sr-only">Close dialogue</span>
        </button>
        <p
          dangerouslySetInnerHTML={{
            __html: safeContent(),
          }}
        />
      </div>
      <div className="tw-relative tw-bottom-10 tw-right-5 tw-h-40 tw-transition-opacity tw-duration-300">
        <div className=" tw-z-20 tw-w-24">
          <button
            type="button"
            onClick={() => {
              // Time until expiry: 5 days. TODO: establish better time
              setHidden({ visible: false, ttl: Date.now() + 432000000 });
            }}
            className="tw-text-sans tw-group tw-absolute tw-top-[52%] tw-left-[41%] tw-z-30 tw-rounded-lg tw-bg-anbg-cream tw-py-1 tw-px-2 tw-font-bold tw-uppercase tw-tracking-wide tw-text-anbg-dark-green tw-transition-all hover:tw-scale-110"
          >
            Hide
            <div className="tw-absolute -tw-bottom-3 tw-w-28 tw-translate-y-full tw-rounded-md tw-bg-anbg-cream tw-p-2 tw-opacity-0 tw-transition-opacity tw-duration-200 after:tw-absolute after:tw-bottom-full after:tw-left-2 after:tw-h-5 after:tw-w-5 after:tw-rounded-none after:tw-border after:tw-border-8 after:tw-border-anbg-cream after:tw-border-x-transparent after:tw-border-t-transparent group-hover:tw-block group-hover:tw-opacity-100">
              <p className="tw-font-regular tw-text-condensed tw-hidden tw-text-xs tw-normal-case tw-leading-tight group-hover:tw-block">
                Hide all messages for the next 5 days.
              </p>
            </div>
          </button>
          <img
            src={fencePath}
            alt=""
            className="tw-absolute tw-top-[40%] tw-left-[30%] tw-z-20 tw-w-24"
          />
        </div>

        {explorerFallback === ImageEnum['01'] && (
          <div className=" tw-absolute tw-bottom-[40%] tw-left-[8%] tw-z-10 tw-flex tw-w-40 md:tw-w-56">
            <img
              src={beeFlippedPath}
              alt=""
              className="floatAnimation -tw-mr-6 tw-w-1/2 tw-transform"
            />
            <img
              src={banksiaPath}
              alt=""
              className="bobAnimation tw-z-10 tw-w-1/2"
            />
          </div>
        )}
        {explorerFallback === ImageEnum['02'] && (
          <img
            src={beePath}
            className="floatAnimation tw-absolute tw-bottom-[20%] tw-left-[30%] tw-z-10 tw-w-24 tw-transform "
            alt=""
          />
        )}
        {explorerFallback === ImageEnum['03'] && (
          <img
            src={banksiaPath}
            className="bobAnimation tw-absolute tw-bottom-[30%] tw-left-[20%] tw-z-10 tw-w-24"
            alt=""
          />
        )}
        {explorerFallback === ImageEnum['04'] && (
          <img
            src={beeFlippedPath}
            className="floatAnimation tw-absolute tw-bottom-[20%] tw-left-[30%] tw-z-10 tw-w-24"
            alt=""
          />
        )}
        <img
          src={islandPath}
          alt=""
          className="tw-absolute tw-bottom-0 tw-w-48 md:tw-w-52"
        />
      </div>
    </div>
  );
}

import React from 'react';
import { Link } from 'gatsby';

const park = process.env.GATSBY_PARK;

const regex = new RegExp(`^/${park}`);

interface IPlainLink {
  uri: string;
  text: string;
  className?: string;
}

export default function AtomPaLink({
  uri,
  text,
  className,
  ...props
}: IPlainLink) {
  let to = uri.replace(regex, '');

  if (to === '') {
    to = '/';
  }

  const classes = `lh-btn ${className}`;

  if (to.startsWith('/')) {
    return (
      <Link className={classes} to={to} {...props}>
        {text}
      </Link>
    );
  }

  if (to.startsWith('internal:')) {
    to = to.replace('internal:', '');
    return (
      <Link className={classes} to={to} {...props}>
        {text}
      </Link>
    );
  }

  return (
    <a className={classes} href={to} {...props}>
      <span>{text}</span>
    </a>
  );
}

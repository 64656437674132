import React from 'react';
import { Link } from 'gatsby';

function InfoBox({ children, buttonTitle, link, close, className = '' }, ref) {
  return (
    <div
      ref={ref}
      className={`tw-absolute -tw-top-16 tw-left-1/2 tw-z-10 tw-mt-4 tw-w-72 -tw-translate-x-1/2 tw-overflow-hidden tw-pt-4 tw-text-center ${className}`}
    >
      <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-rounded-lg tw-bg-white tw-p-6">
        <button
          type="button"
          className="tw-absolute tw-top-0 tw-h-10 tw-w-10 tw-rounded-full tw-bg-anbg-orange tw-text-white"
          onClick={close}
        >
          X
        </button>
        <div className="tw-pt-6">{children}</div>
        <div>
          <Link to={link} className="lh-btn lh-btn-green">
            {buttonTitle}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default React.forwardRef(InfoBox);

import React, { useState } from 'react';
import { Transition } from '@headlessui/react';

import './animation.css';
import firstIsland from '../../../images/islands/1.svg';
import secondIsland from '../../../images/islands/2.svg';
import thirdIsland from '../../../images/islands/3.svg';
import fourthIsland from '../../../images/islands/4.svg';
import fifthIsland from '../../../images/islands/5.svg';
import sixthIsland from '../../../images/islands/6.svg';
import seventhIsland from '../../../images/islands/7.svg';
import firstCloud from '../../../images/islands/clouds1.svg';
import bridge from '../../../images/islands/bridge.svg';
import teachersPass from '../../../images/islands/8.svg';
import AtomTitle from '../../atom/title';
import AtomInfoBox from '../../atom/info-box';
import AtomDrupalContent from '../../atom/drupal-content';

function Bridge({ classes = '', imageClasses = '' }) {
  return (
    <div className={`tw-absolute ${classes}`}>
      <img
        src={bridge}
        alt=""
        aria-hidden="true"
        className={`tw-w-32 ${imageClasses}`}
      />
    </div>
  );
}

function Island({
  outerClasses = '',
  island,
  content,
  islandClasses = '',
  bridgesFirst = false,
  bridges = [],
  select = () => {},
  selected = false,
  to = '/',
  buttonTitle = 'Coming Soon',
}) {
  const b = bridges.map((br, idx) => <Bridge key={idx} {...br} />);

  return (
    <div className={`tw-relative ${outerClasses}`}>
      <Transition
        show={selected}
        as={AtomInfoBox}
        link={to}
        buttonTitle={buttonTitle}
        close={() => select(null)}
        enter="tw-transition-transform tw-duration-300"
        enterFrom="tw-translate-y-1/2 tw-scale-0"
        enterTo="tw-translate-y-0 tw-scale-100"
        leave="tw-transition-transform tw-duration-300"
        leaveFrom="tw-translate-y-0 tw-scale-100"
        leaveTo="tw-translate-y-1/2 tw-scale-0"
      >
        {content}
      </Transition>
      {bridgesFirst && b}
      <div>
        <button
          type="button"
          className={`tw-scale-100 tw-transition-transform tw-duration-500 tw-ease-in-out hover:tw-scale-105 ${islandClasses}`}
          onClick={() => select(island)}
        >
          <img src={island} alt={to} className="tw-w-full" />
        </button>
      </div>
      {!bridgesFirst && b}
    </div>
  );
}

export default function Islands({ title, description }) {
  const islands = [
    {
      island: firstIsland,
      islandClasses: 'tw-w-40 2xl:tw-w-56',
      bridges: [{ classes: 'tw-bottom-10 -tw-right-24 tw-rotate-12 ' }],
      to: '/plant-life-cycle',
      buttonTitle: 'Explore now',
      content: (
        <p>
          A life cycle is the series of stages all living organisms go through
          from the beginning to the end of their life. Learn all about the
          fascinating life cycle of flowering plants.
        </p>
      ),
    },
    [
      {
        island: thirdIsland,
        islandClasses: 'tw-w-72 2xl:tw-w-80',
        bridgesFirst: true,
        bridges: [{ classes: 'tw-bottom-0 tw-right-10 tw-rotate-90' }],

        to: '/pollination',
        buttonTitle: 'Explore now',
        content: (
          <p>
            How does a tiny grain of pollen get from one flower to another? What
            tricks do plants use to ensure they are pollinated? Explore
            pollination, pollinators and pollination syndromes and discover why
            they are so important for the survival of plants.
          </p>
        ),
      },
      {
        island: secondIsland,
        islandClasses: 'tw-ml-16 2xl:tw-w-64',
        to: '/plant-structure',
        buttonTitle: 'Explore now',
        content: (
          <p>
            Plants come in all kinds of weird and wonderful shapes and sizes,
            but they all have the same basic structure and function.
          </p>
        ),
      },
    ],
    {
      outerClasses: 'tw-w-80',
      island: fourthIsland,
      islandClasses: 'tw-mb-20 tw-w-full',
      bridgesFirst: true,
      bridges: [
        {
          classes: 'tw-top-0 -tw-left-16 tw-rotate-12 2xl:tw-top-[10%]',
          imageClasses: '2xl:tw-w-40',
        },
      ],
      to: '/seeds',
      buttonTitle: 'Explore now',
      content: (
        <p>
          Soft, hard, weird and wacky, it's time to discover the potential of
          seeds!
        </p>
      ),
    },
    // [
    //   {
    //     island: seventhIsland,
    //     islandClasses: 'tw-w-48 2xl:tw-w-56',
    //     to: '/ecosystems',
    //     buttonTitle: 'Explore now',
    //     content: (
    //       <p>
    //         Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa
    //         blanditiis facere, labore.
    //       </p>
    //     ),
    //   },
    //   {
    //     island: fifthIsland,
    //     islandClasses: 'tw-w-56 2xl:tw-w-64',
    //     bridgesFirst: true,
    //     bridges: [
    //       {
    //         classes: 'tw-top-0 -tw-left-16 tw-rotate-45 2xl:tw-top-[10%]',
    //         imageClasses: '2xl:tw-w-40',
    //       },
    //     ],
    //     to: '/classification',
    //     buttonTitle: 'Explore now',
    //     content: (
    //       <p>
    //         Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa
    //         blanditiis facere, labore.
    //       </p>
    //     ),
    //   },
    // ],
    // {
    //   island: sixthIsland,
    //   bridgesFirst: true,
    //   islandClasses: 'tw-w-72 2xl:tw-w-80',
    //   bridges: [
    //     { classes: 'tw-top-10 -tw-left-16 tw-rotate-45' },
    //     {
    //       classes: '-tw-left-10 tw-bottom-0 2xl:tw-bottom-[10%]',
    //       imageClasses: '2xl:tw-w-40',
    //     },
    //   ],
    //   to: '/adaptation',
    //   buttonTitle: 'Explore now',
    //   content: (
    //     <p>
    //       How do plants become what they are today? Observe how different plant
    //       species have changed to suit the environment around them
    //     </p>
    //   ),
    // },
  ];
  const mobileIslands = [
    {
      island: firstIsland,
      outerClasses: 'tw-w-56',
      buttonTitle: 'Explore now',
      to: '/plant-life-cycle',
      content: (
        <p>
          The series of stages all living organisms (both plants and animals) go
          through from the beginning of its life until the end.
        </p>
      ),
    },

    {
      island: secondIsland,
      outerClasses: 'tw-w-64',
      to: '/plant-structure',
      buttonTitle: 'Explore now',
      content: (
        <p>
          Plants come in all kinds of weird and wonderful shapes and sizes, but
          they all have the same basic structure and function.
        </p>
      ),
    },

    {
      island: thirdIsland,
      outerClasses: 'tw-w-80',
      to: '/pollination',
      buttonTitle: 'Explore now',
      content: (
        <p>
          How does a tiny grain of pollen get from one flower to another? What
          tricks do plants use to ensure they are pollinated? Explore
          pollination, pollinators and pollination syndromes and discover why
          they are so important for the survival of plants.
        </p>
      ),
    },

    {
      island: fourthIsland,
      outerClasses: '',
      islandClasses: 'tw-w-72 ',
      to: '/seeds',
      buttonTitle: 'Explore now',
      content: (
        <p>
          Soft, hard, weird and wacky, it's time to discover the potential of
          seeds!
        </p>
      ),
    },

    // {
    //   island: fifthIsland,
    //   outerClasses: ' ',
    //   islandClasses: 'tw-w-64 ',
    //   to: '/',
    // },

    // {
    //   island: sixthIsland,
    //   islandClasses: 'tw-w-80',
    //   outerClasses: '',
    //   to: '/adaptation',
    //   buttonTitle: 'Explore now',
    //   content: (
    //     <p>
    //       How do plants become what they are today? Observe how different plant
    //       species have changed to suit the environment around them
    //     </p>
    //   ),
    // },
    // {
    //   island: seventhIsland,
    //   outerClasses: '',
    //   to: '/ecosystems',
    //   buttonTitle: 'Explore now',
    //   content: (
    //     <p>
    //       Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa
    //       blanditiis facere, labore.
    //     </p>
    //   ),
    // },
  ];

  const [selectedIsland, setSelectedIsland] = useState(null);

  return (
    <div className="tw-relative tw-z-20 tw-flex tw-flex-col tw-items-center tw-justify-center tw-overflow-x-hidden   tw-py-14 tw-px-4  lg:tw-pt-32 xl:tw-px-12">
      <div className="tw-relative tw-z-10 tw-mx-auto tw-space-y-6 tw-text-center lg:tw-w-9/12">
        <AtomTitle title={title} />
        <div className="tw-mx-auto tw-max-w-3xl">
          <AtomDrupalContent content={description} />
        </div>
      </div>

      <div className="py-32 max-w-7xl mx-auto tw-relative tw-w-full">
        <div className="cloud-container-delay space-x-24 tw-absolute tw-mx-auto tw-flex tw-w-full tw-items-center tw-justify-between xl:tw-top-10">
          {[0, 1, 2].map((key) => (
            <div key={key}>
              <img src={firstCloud} alt="" aria-hidden="true" />
            </div>
          ))}
        </div>
        <div className="cloud-container space-x-24 tw-absolute tw-mx-auto tw-flex tw-w-full tw-items-center tw-justify-between xl:tw-top-10">
          {[0, 1, 2].map((key) => (
            <div key={key}>
              <img src={firstCloud} alt="" aria-hidden="true" />
            </div>
          ))}
        </div>
      </div>

      <div className="tw-mt-32 tw-hidden 2xl:tw-block">
        <div className="tw-flex tw-items-center">
          {islands.map((island, idx) =>
            Array.isArray(island) ? (
              <div key={idx}>
                {island.map((isle, i) => (
                  <Island
                    key={i}
                    selected={selectedIsland === isle.island}
                    select={setSelectedIsland}
                    {...isle}
                  />
                ))}
              </div>
            ) : (
              <Island
                key={idx}
                selected={selectedIsland === island.island}
                select={setSelectedIsland}
                {...island}
              />
            ),
          )}
        </div>
      </div>

      <div className="tw-mt-48 tw-max-w-2xl lg:tw-max-w-full 2xl:tw-hidden">
        <div className="tw-flex tw-flex-col tw-flex-wrap tw-items-center tw-justify-center tw-space-y-10 md:tw-flex-row md:tw-space-y-0">
          {mobileIslands.map((island, idx) =>
            Array.isArray(island) ? (
              <div key={idx}>
                {island.map((isle, i) => (
                  <Island
                    key={i}
                    selected={selectedIsland === isle.island}
                    select={setSelectedIsland}
                    {...isle}
                  />
                ))}
              </div>
            ) : (
              <div className="tw-flex tw-justify-center md:tw-w-1/2 lg:tw-w-1/3">
                <Island
                  key={idx}
                  selected={selectedIsland === island.island}
                  select={setSelectedIsland}
                  {...island}
                />
              </div>
            ),
          )}
        </div>
      </div>
      <div className="tw-mx-auto tw-flex tw-w-full tw-max-w-7xl tw-justify-end md:-tw-mt-10">
        <a
          href="/teacher-resources"
          className="tw-scale-100 tw-transition-all tw-duration-500 tw-ease-in-out hover:tw-scale-105 lg:tw-self-start"
        >
          <img
            src={teachersPass}
            alt="Teacher's Resources"
            className="tw-w-56 3xl:tw-w-64"
          />
        </a>
      </div>
    </div>
  );
}

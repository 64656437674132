import React from 'react';
import { graphql } from 'gatsby';
import slugify from 'slugify';
import AtomDrupalContent from '../atom/drupal-content';
import AtomPaLink from '../atom/plain-link';
import topOrange from '../../images/top-orange.svg';
import bottomOrange from '../../images/bottom-orange.svg';
import topGreen from '../../images/top-green.svg';
import bottomGreen from '../../images/bottom-green.svg';

export default function TwoThirdsImageText({ node }) {
  const urlPath = process.env.GATSBY_IMAGE_BASE || process.env.DRUPAL_API_URL;

  function image() {
    if (node.relationships.image.relationships != null) {
      return (
        urlPath +
        node.relationships.image.relationships.field_media_image_4.uri.url
      );
    }
    return null;
  }
  function idSlug() {
    let id = '';
    if (node.title) {
      const firstTwoWords = node.title.split(' ').slice(0, 2).join(' ');

      id = slugify(firstTwoWords, {
        lower: true,
        strict: true,
      });
    } else {
      id = `section-${node.id}`;
    }

    return id;
  }

  const buttons = node.links.map((link, index) => (
    <AtomPaLink key={`button-${index}`} text={link.title} uri={link.uri} />
  ));
  const bgClass =
    node.bgColour === 'green' ? 'tw-bg-anbg-dark-green' : 'tw-bg-anbg-orange  ';

  const textColour =
    node.bgColour === 'green'
      ? 'tw-text-anbg-cream'
      : 'tw-text-white tw-prose-white ';

  const reverseClass = node.reverseBlock
    ? ' xl:tw-flex-row-reverse xl:tw-space-x-reverse 2xl:tw-space-x-reverse'
    : '';

  const topShape = node.bgColour === 'green' ? topGreen : topOrange;

  const bottomShape = node.bgColour === 'green' ? bottomGreen : bottomOrange;

  return (
    <section id={idSlug()} className="tw-group">
      <div
        className={`twothirds tw-relative tw-my-20 tw-py-12 tw-px-4  group-last:tw-mb-0 xl:tw-py-24 xl:tw-px-12 2xl:tw-my-40  2xl:tw-py-32 ${bgClass}`}
      >
        <div className="tw-absolute tw-bottom-[100%] tw-left-0 tw-z-10 tw-w-full ">
          <img src={topShape} alt="" />
        </div>
        <div className="tw-absolute tw-top-[100%] tw-left-0 tw-z-10 tw-w-full group-last:tw-hidden">
          <img src={bottomShape} alt="" />
        </div>
        <div
          className={`tw-mx-auto tw-w-full tw-max-w-screen-2xl tw-items-center tw-justify-center tw-text-anbg-cream xl:tw-flex 2xl:tw-space-x-20 ${reverseClass}`}
        >
          <div className="tw-flex tw-flex-col xl:tw-w-1/2 ">
            <h2
              className={`textLarge tw-pb-4 tw-font-serif xl:tw-pb-10 ${textColour}`}
            >
              {node.title}
            </h2>
            <AtomDrupalContent
              content={node.text.processed}
              className={textColour}
            />

            <div className="md:tw-space-x-4">{buttons}</div>
          </div>
          <div className="tw-mt-10 tw-w-full xl:tw-mt-0 xl:tw-w-1/2">
            {node.relationships.image != null && (
              <img src={image()} alt="" className="tw-w-full" />
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export const fragment = graphql`
  fragment ParagraphTwoThirds on paragraph__anbg_lh_twothirds {
    id
    title: field_anbg_lh_title
    text: field_anbg_inline_text {
      processed
    }
    reverseBlock: field_anbg_lh_reverse
    bgColour: field_anbg_lh_background_colour
    relationships {
      image: field_anbg_lh_img {
        ... on media__anbg_lh_inline_img {
          relationships {
            field_media_image_4 {
              uri {
                url
              }
            }
          }
        }
      }
    }
    links: field_anbg_lh_cta {
      title
      uri
    }
  }
`;

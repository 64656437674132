import React from 'react';
import slugify from 'slugify';
import { graphql } from 'gatsby';

function YoutubeEmbed(prop) {
  return (
    <div className="responsive-embed responsive-embed--with-poster-image mt-xsmall mb-medium">
      <div className="container">
        <iframe
          className="responsive-iframe"
          src={`https://player.vimeo.com/video/${prop.embedId}?autoplay=0&rel=0showinfo=0`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title={`${prop.videoTitle}`}
        />
      </div>
    </div>
  );
}

export default function EmbedVideoParagraph({ node }) {
  function idSlug() {
    let id = '';
    if (node.field_video_title?.value) {
      const firstTwoWords = node.field_video_title.value
        .split(' ')
        .slice(0, 2)
        .join(' ');

      id = `video-${slugify(firstTwoWords, {
        lower: true,
        strict: true,
      })}`;
    } else {
      id = `video-${node.field_video_id}`;
    }

    return id;
  }

  let embedVideoSection;
  if (node?.field_video_title?.value != null && node?.field_video_id != null) {
    const videoEmbedId = node?.field_video_id;
    const videoTitle = node?.field_video_title?.value;
    embedVideoSection = (
      <YoutubeEmbed embedId={videoEmbedId} videoTitle={videoTitle} />
    );
  }

  return (
    <section
      id={idSlug()}
      className="pb-medium tw-mx-auto tw-max-w-7xl tw-px-6 xl:tw-px-10"
    >
      <div className="grid-wrapper">
        <div className="grid-row clearfix">
          <div className="grid-col grid-col--8 grid-col--push-2 tb-grid-col--10 tb-grid-col--push-1 ph-grid-col--12 ph-grid-col--push-0">
            <div className="copy">{embedVideoSection}</div>
          </div>
        </div>
      </div>
    </section>
  );
}

export const fragment = graphql`
  fragment ParagraphEmbedvideo on paragraph__embed_video {
    field_video_id
    field_video_title {
      value
    }
  }
`;
